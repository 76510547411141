import React, { useState, useEffect } from "react"
import marblehead from "../images/marblehead_flyer.jpeg"
import tower from "../images/bell_tower.jpeg"
import lumen from "../images/lumen.webp"
import pageant_soloveev from "../images/pageant_soloveev.jpeg"
import mini_mart from "../images/mini_mart.jpeg"

import i_will_cry from "../images/i_will_cry.jpeg"
import seaweed from "../images/seaweed.jpeg"
import dinner from "../images/dinner.jpeg"
import trillium from "../images/trillium.jpeg"

import "../styles/index.sass"

const IndexPage = () => {
  const [shown, setShown] = useState("")

  const handleClick = (c) => setShown(c === shown ? "" : c)

  useEffect(() => {
    document.title = "heron.zone"
  }, [])

  return (
    <main>
      <h2>heron.zone</h2>
      <div>
        <img src={i_will_cry} alt="I will cry" height={500} width={375} />
        <img src={seaweed} alt="Seaweed" height={500} width={375} />
      </div>
      <div>
        {" "}
        <img src={dinner} alt="dinner" height={500} width={375} />
        <img src={trillium} alt="Trillium" height={500} width={375} />
      </div>
      <p>
        ella heron is a <span onClick={() => handleClick("sound")}>sound</span>{" "}
        artist/educator, fullstack{" "}
        <span onClick={() => handleClick("software")}>software</span> developer,
        and land steward living on occupied lenapehoking.
      </p>

      {shown === "sound" && (
        <>
          <div className="sound">
            <iframe
              src="https://bandcamp.com/EmbeddedPlayer/track=1371127230/size=large/bgcol=333333/linkcol=0f91ff/minimal=true/transparent=true/"
              seamless
            >
              <a href="https://44rupture.bandcamp.com/track/parte-parcell">
                parte, parcell by ella heron
              </a>
            </iframe>
            <iframe
              src="https://bandcamp.com/EmbeddedPlayer/album=3717487812/size=large/bgcol=333333/linkcol=0f91ff/minimal=true/transparent=true/"
              seamless
            >
              <a href="https://44rupture.bandcamp.com/album/ancient-bootlegs">
                • ancient bootlegs • by ella heron
              </a>
            </iframe>
            <iframe
              width="100%"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/718525333&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
            ></iframe>
            <iframe
              src="https://bandcamp.com/EmbeddedPlayer/album=1191405908/size=large/bgcol=ffffff/linkcol=7137dc/minimal=true/transparent=true/"
              seamless
            >
              <a href="https://dyani.bandcamp.com/album/under">
                Under by Dyani
              </a>
            </iframe>
            <a href="https://web.archive.org/web/20210419132113/http://hoursof.work/">
              <img className="mockIframe" src={tower} />
            </a>
            <iframe
              src="https://open.spotify.com/embed/track/3QT6eqQfuWJtYLU9WQ5I2h?utm_source=generator"
              width="50%"
              height="352"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
          <div style={{ textAlign: "center", margin: "2rem" }}>recently:</div>
          <div className="flyerContainer">
            <a
              href="https://ra.co/events/1837772"
              target="_blank"
              rel="noreferrer"
            >
              <img src={lumen} alt="Lumen" />
            </a>
            <img src={pageant_soloveev} alt="Pageant Soloveev" />
            <img src={mini_mart} alt="Mini Mart" />
            {/* <img src={marblehead} alt="Marblehead" /> */}
          </div>
        </>
      )}
      {shown === "software" && (
        <div className="software">
          <div>
            <a href="https://jesshackel.works" target="_blank">
              Jess Hackel
            </a>{" "}
            (design and development)
          </div>
          <div>
            <a href="https://redlines.nwu.org" target="_blank">
              Red Lines: Retaliation in the media industry during the war on
              Gaza
            </a>{" "}
            (development)
          </div>
          <div>
            <a href="https://ballotready.org" target="_blank">
              BallotReady
            </a>{" "}
            (frontend developer, 2022-2024)
          </div>
          <div>
            <a href="https://synagogue.yeshiva.lgbt" target="_blank">
              The Queer Yeshiva Synagogue
            </a>{" "}
            (design and development)
          </div>
          <div>
            <a href="https://thelastemporium.hk" target="_blank">
              The Last Emporium
            </a>{" "}
            (frontend development)
          </div>

          <div>
            <a href="http://mosquit.ooo" target="_blank">
              mosquit.ooo
            </a>{" "}
            (design and development)
          </div>
          <div>
            <a href="https://ellatenar.github.io/PHL-H20/" target="_blank">
              PHL H<sub>2</sub>0
            </a>{" "}
            (design and development)
          </div>
        </div>
      )}

      <p>get in touch: heron sound 95 at gmail dot com</p>
    </main>
  )
}

export default IndexPage
